
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import DocumentIssuanceMaintenance from "./DocumentIssuanceMaintenance";
import { DocumentIssuanceHeaderFormPanel } from "./Form/DocumentIssuanceHeaderFormPanel";
import { DocumentIssuanceEditPanel } from "./DocumentIssuanceEditPanel";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";


export const DocumentIssuanceMasterView: React.FC = () => {
    const [docIssuanceState] = useDocumentIssuanceTracked();
    //const docIssuanceVM = useDocumentIssuanceVM();

    const { isAdd, isEditable, isSliderOpen } = docIssuanceState.masterState;
    //const [isLoading, setIsLoading] = useState(false);

    /*useEffect(() => {
        if(isAdd || isEditable){
            const initialScreen = async() => {   
                setIsLoading(true);
                try {     
                    const results = await Promise.allSettled([
                        docIssuanceVM.loadDropdownOption(),                    
                    ]);
                    results.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {

                        } 
                    })
                } catch(error) {
                    setIsLoading(false);
                }
            }
            initialScreen().then(async () => {
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }
        
    }, [docIssuanceVM, isAdd, isEditable])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;*/
    return <>
        <div className="main-comp-wrapper">            
            <DocumentIssuanceMaintenance/>            
            {
                ((isAdd || isEditable) &&
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"37%"}
                    rightSectionWidth={"63%"}
                    leftChildren={<DocumentIssuanceHeaderFormPanel/>}
                    rightChildren={<DocumentIssuanceEditPanel/>}
                />
                )
            }
        </div>
    </>
}