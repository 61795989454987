import { SelectionChangedEvent } from "ag-grid-community";
import { DocumentIssuanceEntity } from "domain/entity/Document/DocumentIssuanceEntity";
import { INITIAL_DOCUMENT_ISSUANCE_COL_DEF, transferRowData } from "presentation/constant/DocumentIssuance/DocumentIssuanceColumnDefinition";
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";

import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const DocumentIssuanceTablePanel:React.FC = () => {
    const [documentIssuanceState] = useDocumentIssuanceTracked();
    const documentIssuanceVM = useDocumentIssuanceVM();
    const gridRef: any = useRef(null);
    const {tableData} = documentIssuanceState;
    
    // useEffect(() => {
    //     const columnDefs = (INITIAL_TARIFF_CODE_COL_DEF.slice());

    //     if (!selectedRows || selectedRows.length <= 0) {
    //         gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    //         if (!onTableSelectionClicked) {
    //             gridRef.current?.gridRef.current.api?.deselectAll();
    //         }
    //     }
    // })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        documentIssuanceVM.updateSelectedRows(tableData,selectedRows);
      }, [documentIssuanceVM, tableData])

    // useEffect(() => {
    //         if (onTableSelectionClicked) return;
            
    //         gridRef?.current?.gridRef.current.api?.deselectAll();
    //     }, [onTableSelectionClicked])

      const handleRowDoubleClick = useCallback((entity: DocumentIssuanceEntity) => {
        documentIssuanceVM.onRowDoubleClick(entity);
    }, [documentIssuanceVM])

    /*useEffect(() => {
        const handleRightClickMenu = (event:any) => {            
            if(_.isEmpty(selectedRows) || !allowUpdate){                
                return; 
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
          document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, selectedRows])
   

    const moveItemList: MenuItem[] = useMemo(() => {
        const menuItemArray: MenuItem[] = [];

        menuItemArray.push({
            label: 'Move After',
            items:subMenuItemArray
        })
        return menuItemArray;
    },[subMenuItemArray]);

    const handleRowDrag = useCallback( (e: any, updatedRows: StandardTariffCodeEntity[], movedIndex: number, overIndex: number)=>{
        tariffCodeVM.onRowDrag(updatedRows);
    },[tariffCodeVM])*/

    const memoDocIssuanceTable = useMemo(() => {

        return (<div id="myDiv">
            {/*(allowUpdate) && <RightClickMenu disabled={_.isEmpty(selectedRows)} element={rightClickRef} items={moveItemList} />*/}
        <HPHTable
            id='doc-issuance-table'
            isNewColumnSetting={true}
            columns={INITIAL_DOCUMENT_ISSUANCE_COL_DEF}
            data={transferRowData(tableData)??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: DocumentIssuanceEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            //isRowDrag={true}
            //onRowDragged={handleRowDrag}
            />
        </div>
        );
    },[handleRowDoubleClick, handleSelectionChange, tableData])

    return <><TableWrapper>
            {memoDocIssuanceTable}
        </TableWrapper>
        </>;
}

export default memo(DocumentIssuanceTablePanel);
