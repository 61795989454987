import { DocumentIssuanceConstant } from "presentation/constant/DocumentIssuance/DocumentIssuanceConstant";
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const DocumentIssuanceEditPanelTitleBar: React.FC = () => {
    const [documentIssuanceState] = useDocumentIssuanceTracked();
    const documentIssuanceVM = useDocumentIssuanceVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow,masterState} = documentIssuanceState;
    const {editingEntity, isAdd, isEditable, isRead} = masterState;
    
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate, allowUpdate} = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead?currentSelectedRow:editingEntity;
    
    const isDisable = () => {
        /*if (isCompAdd || (!isCompAdd && isCompEdit)) return true;*/

        return false;
    }

    const handleReset = useCallback(async () => {
        documentIssuanceVM.onResetClick();
    }, [documentIssuanceVM]);

    const handleEdit = useCallback(()=>{
        documentIssuanceVM.onEdit(currentEntity);
    },[currentEntity, documentIssuanceVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            documentIssuanceVM.onSaveClicked();
            const res = await documentIssuanceVM.onSave(currentEntity,isAdd);
            if(res && res["mandatoryCheckFail"]){
                messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
                return;
            }else if(res){
                messageBarVM.showError(res);
                return;
            }

            //documentIssuanceVM.searchDocIssuance();
        } catch (error) {

        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, documentIssuanceVM, isAdd, messageBarVM]);

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: DocumentIssuanceConstant.TITLE},
                { title: DocumentIssuanceConstant.TITLE }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={documentIssuanceVM.onCloseClick} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={documentIssuanceVM.onCloseClick} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(DocumentIssuanceEditPanelTitleBar);