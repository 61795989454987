
import { DocumentIssuanceConstant } from "presentation/constant/DocumentIssuance/DocumentIssuanceConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const DocumentIssuanceTitleBar:React.FC = () => {
    const [docIssuanceState] = useDocumentIssuanceTracked();
    const docIssuanceVM = useDocumentIssuanceVM();
    //const messageBarVM = useMessageBarVM();
    //const {updatedRows} = docIssuanceState;
    const TARIFF_CODE_CONSTANT = DocumentIssuanceConstant;
    const [ isLoading ] = useState<boolean>(false);

    const handleAdd = useCallback(() => {
        docIssuanceVM.onAddClick();
    }, [docIssuanceVM]);

    const handleApply = useCallback(async () => {
        /*if(_.isEmpty(updatedRows)) return;
        setLoading(true);
        const res = await tariffCodeVM.onApply(updatedRows);
        setLoading(false);
        if(!!!res || !res.success){
            messageBarVM.showWarining('Apply failed.');
        }else{
            await tariffCodeVM.searchTariffCode({});
        }
        setLoading(false);*/
    }, []);

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{TARIFF_CODE_CONSTANT.TITLE}</HeaderTitle>
        {(docIssuanceState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
       
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} /> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={WorkspaceConstant.Common.BUTTON_ADD} onClick={handleAdd}/>
            <div className="add-seperator"/>            
            <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={false} onClick={handleApply} />
        </StyledAction>
    </Sidebarheader>
}

export default memo(DocumentIssuanceTitleBar);