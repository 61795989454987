import { SelectionChangedEvent } from "ag-grid-community";
import { DocumentSupportingEntity } from "domain/entity/Document/DocumentSupportingEntity";
import { DocumentIssuanceConstant } from "presentation/constant/DocumentIssuance/DocumentIssuanceConstant";
import { INITIAL_DOCUMENT_SUPPORTING_COL_DEF, transferRowData } from "presentation/constant/DocumentIssuance/DocumentSupportingColumnDefinition";
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";

const DocumentSupportingTablePanel:React.FC = () => {
    const [docIssuanceState] = useDocumentIssuanceTracked();
    const docIssuanceVM = useDocumentIssuanceVM();
    const {masterState,detailState} = docIssuanceState;
    const {docSupportingList, isEditable, isAdd} = detailState;
    const isMasterRead = masterState.isRead
    
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_DOCUMENT_SUPPORTING_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        docIssuanceVM.updateSelectedSupportingRows(selectedRows);
      }, [docIssuanceVM])

    const handleRowDoubleClick = useCallback((docSupporting: DocumentSupportingEntity) => {
        if( isAdd || isEditable) return;
        docIssuanceVM.onSupportingRowClick(docSupporting);
    }, [docIssuanceVM, isAdd, isEditable])

    const handleAddClick = useCallback(async() => {
        docIssuanceVM.onSupportingAdd();      
    },[docIssuanceVM])

    const memoDocSupportingTable = useMemo(() => {
        return <HPHTable
            id='doc-supporting-table'
            headerLabel={DocumentIssuanceConstant.Detail.TITLE}            
            columns={INITIAL_DOCUMENT_SUPPORTING_COL_DEF.slice()}
            data={transferRowData(docSupportingList??[])}
            //headerActionButtons={tbBtns}
            showPaginator={false}
            editable={false}
            showAddIcon={!isMasterRead && !isEditable && !isAdd}            
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef} />;
    }, [docSupportingList, isMasterRead, isEditable, isAdd, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <>
    { <TableWrapper>{memoDocSupportingTable}</TableWrapper>}
    </>;
}

export default memo(DocumentSupportingTablePanel);